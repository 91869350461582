import React from 'react'
import Helmet from 'react-helmet'

import Container from '../components/container'
import Navigation from '../components/navigation'
import Footer from '../components/Footer/Footer'

class Template extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    const { children } = this.props

    return (
      <Container>
        <Helmet>
          <meta name="google-site-verification" content="Rpq-Dd60dEDkoSdqBK7XRa0Csiijsenk4JUDGlXcKcc" />
          <link
            rel="stylesheet"
            href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
            integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
            crossorigin="anonymous"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Kalam&display=swap" rel="stylesheet"></link>
        <Navigation />
        <div className="main-wrap">{children}</div>
        <Footer />
      </Container>
    )
  }
}

export default Template
