import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons'

import './navigation.scss'

const OvationLogo = 'https://d1syj4d8txnu77.cloudfront.net/Navigation/ovation-logo.svg'

const Navigation = () => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [resourcesHover, setResourcesHover] = useState(false)
  const [resourcesMobileShow, setResourcesMobileShow] = useState(false)

  const closeMobileMenu = () => {
    setMobileMenu(false)
    setResourcesMobileShow(false)
  }

  return (
    <header className="navigation-header">
      <div className="header-inner">
        <Link to="/">
          <div className="logo" onClick={closeMobileMenu}>
            <img src={OvationLogo} />
          </div>
        </Link>
        <div className="buffer" />
        <div onClick={() => (mobileMenu ? closeMobileMenu() : setMobileMenu(true))} className="mobile-menu-button">
          {mobileMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </div>
        <div className={'navigation-wrap' + (mobileMenu ? ' show' : '')}>
          <div className="navigation">
            <div onClick={closeMobileMenu} className="navigationItem">
              <Link to="/get-started">How To Get Started</Link>
            </div>
            <div onClick={closeMobileMenu} className="navigationItem">
              <Link to="/pricing">Pricing</Link>
            </div>
            <div onClick={closeMobileMenu} className="navigationItem">
              <Link to="/education">Education</Link>
            </div>
            <div
              onClick={() => setResourcesMobileShow(!resourcesMobileShow)}
              onMouseEnter={() => setResourcesHover(true)}
              onMouseLeave={() => setResourcesHover(false)}
              className="navigationItem bottom-long"
            >
              <a>
                Resources <FontAwesomeIcon icon={faCaretDown} size="sm" />
              </a>
              <ul
                style={{
                  display: resourcesHover ? 'block' : 'none'
                }}
                className="navigationHoverDropdown"
              >
                <li>
                  <a target="_blank" href="https://docs.ovationvr.com" className="gtm-outbound-link-documentation-home">
                    Documentation
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://docs.ovationvr.com/ovation/faq/standalone-vr"
                    className="gtm-outbound-link-faq-home"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <ul
              onClick={closeMobileMenu}
              className={'navigationItem mobileOnly' + (resourcesMobileShow ? ' resourcesMobileShow' : '')}
            >
              <li>
                <a target="_blank" href="https://docs.ovationvr.com" className="gtm-outbound-link-documentation-home">
                  Documentation
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://docs.ovationvr.com/ovation/faq/standalone-vr"
                  className="gtm-outbound-link-faq-home"
                >
                  FAQ
                </a>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="navigationItem divider" />
            <div onClick={closeMobileMenu} className="navigationItem">
              <a href="https://app.prod.ovationvr.com/signin" className="gtm-outbound-link-faq-home">
                Sign In
              </a>
            </div>
          </div>

          <div className="d-inline-block" onClick={closeMobileMenu}>
            <Link className="signup-button" to="/contact">
              Request a Demo
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navigation
