import React from 'react'
import { Link } from 'gatsby'
import './Footer.scss'

const OvationTextLogo = 'https://d1syj4d8txnu77.cloudfront.net/Navigation/ovation-text-logo.svg'

export default () => (
  <footer>
    <div className="footer-inner row align-items-center">
      <div className="col-md-4">
        <Link to="/">
          <img className="ovation-logo" src={OvationTextLogo} />
        </Link>
      </div>
      <div className="col-md-4">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/legal">Legal</Link>
          </li>
        </ul>
      </div>
      <div className="col-md-4">
        <div className="company">&copy; {new Date().getFullYear()} VRSpeaking, LLC</div>
      </div>
    </div>
  </footer>
)
